import {reactive} from "vue";
import {Activity} from "@/app/editor/activity";
import {req} from "@/lib/util";

export interface IThemeImagePreview {
    imageId: string
    version: number
    src: string
    code: number
    loading: boolean
}

class Queue {
    readonly images: { [key: string]: IThemeImagePreview } = reactive({})
    private readonly buffer: IThemeImagePreview[] = []
    private readonly waitBuffer: ((ip: IThemeImagePreview) => void)[] = []


    send(ip: IThemeImagePreview): void {
        if (this.waitBuffer.length > 0) this.waitBuffer.shift()?.(ip)
        else this.buffer.push(ip)
    }

    async receive(): Promise<IThemeImagePreview> {
        const ip = this.buffer.shift()
        if (ip) return ip
        else return new Promise<IThemeImagePreview>((resolve) => {
            this.waitBuffer.push(resolve)
        })
    }
}

class Engine {
    readonly queue = new Queue()
    readonly images: { [key: string]: IThemeImagePreview } = reactive({})
    private activity: Activity | null = null

    constructor() {
        this.serve().then()
    }

    send(id: string, version: number): void {
        if (!(id + version in this.images)) {
            this.images[id + version] = {
                code: 0,
                imageId: id,
                loading: false,
                src: "https://activity.djhdb.cn/media/image/null.jpg",
                version: version
            }
        }
        this.queue.send(this.images[id + version])
    }

    bindActivity(a: Activity) {
        this.activity = a
    }

    async serve() {
        for (; ;) {
            const ip = await this.queue.receive()
            await this.loadImage(ip)
        }
    }

    private loadImage(pi: IThemeImagePreview): Promise<IThemeImagePreview> {
        if (!this.activity) return Promise.reject(new Error('No activity found.'))
        const code = this.activity.code()
        if (pi.code == code) return Promise.resolve(pi)
        if (!pi.imageId) {
            pi.loading = false
            pi.src = 'https://activity.djhdb.cn/media/image/null.jpg'
            return Promise.resolve(pi)
        }
        pi.code = code
        pi.loading = true
        return new Promise((resolve, reject) => {
            if (!this.activity) return Promise.reject(new Error('No activity found.'))
            req({
                url: 'editor/image/preview',
                method: 'post',
                data: {
                    image_id: pi.imageId,
                    version: pi.version,
                    options: this.activity.data.theme_config.theme_options[pi.imageId],
                    title: this.activity.data.title,
                    tags: this.activity.data.tags,
                    ...this.activity.genImageTemplateData()
                },
                config: {responseType: 'blob'},
                success: (rs: Blob) => {
                    pi.loading = false
                    if (rs.size > 1000) pi.src = window.URL.createObjectURL(rs)
                    else pi.code = 0
                    resolve(pi)
                }, fail: e => reject(e), complete: () => pi.loading = false
            })
        })
    }
}

export const engine = new Engine()