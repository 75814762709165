
export const variables: any = {
    '预约热线': {module: {shop: {1: ['shop', 'tel']}}},
    '店名': {module: {shop: {1: ['shop', 'shop_name']}}},
    '拼团价': {module: {group: {1: ['goods', 'sell_price']}}},
    '拼团类型': {module: {group: {1: ['type']}}},
    '商品价值': {module: {group: {1: ['goods', 'total_value']}}},
    '分享人昵称': {wx: ['me', 'nick_name']},
    '分享人头像': {wx: ['me', 'head_img_url']},
}
