import {Module} from "@/app/editor/module";
import {DefineComponent, UnwrapRef} from "vue";
import {Activity} from "@/app/editor/activity";
import {IActivityGift} from "@/app/editor/material/gift";
import ForwardPreview from "@/app/editor/module/v1/forward/ForwardPreview.vue";
import ForwardSetup from "@/app/editor/module/v1/forward/ForwardSetup.vue";
import {IModuleData} from "@/lib/activity";

export interface IShareLimit {
    enable: boolean
    amount: number
}
export interface IForwardConfig {
    share_timeline_limit: IShareLimit
    timeline_like_limit: IShareLimit
    share_local_group_limit: IShareLimit
    complete_count: number
    gifts: IActivityGift[]
}

export class Forward extends Module {
    cfg: UnwrapRef<IForwardConfig>;

    constructor(a: Activity, m: UnwrapRef<IModuleData>) {
        super(a, m, '转发设置', 'fen_xiang', ForwardPreview as DefineComponent, ForwardSetup as DefineComponent);
        this.cfg = m.config as IForwardConfig
    }
}