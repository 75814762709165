import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    InternalAxiosRequestConfig
} from "axios";
import {message} from "ant-design-vue";
import {auth, publish} from "@/lib/session";
import {router} from "@/app";

const request: AxiosInstance = axios.create({
    baseURL: 'https://ts.api.activity.djhdb.cn/pc',
    timeout: 50000,
    withCredentials: false,
    responseType: 'json',
} as AxiosRequestConfig)

const errHandler = async (err: unknown): Promise<unknown> => {
    if (err instanceof AxiosError) {
        publish('request', {
            url: (err.config?.baseURL ?? '') + '/' + err.config?.url,
            method: err.config?.method,
            request: {
                data: err.config?.data,
                ip: err.response?.headers.ip
            },
            response: {status: err.request.status, data: err.request.response},
            duration: performance.getEntriesByName(err.request.responseURL).pop()?.duration ?? -1
        })

        if (err.response !== undefined) {
            if (err.response.status === 403) await router.push({name: 'login'})
            if (err.response.data.error) message.error(err.response.data.error)
            else if (err.response.data instanceof Blob) err.response.data.text().then((s: string) => message.error(JSON.parse(s).error))
            else message.error(`错误：${err.response.status}`)
        }
    }
    return Promise.reject(err)
}

request.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig<unknown> | Promise<InternalAxiosRequestConfig<unknown>> => {
    if (auth?.token) {
        if (config.params) config.params.token = auth.authorization
        else config.params = {token: auth.authorization}
    }
    return config
}, errHandler)

request.interceptors.response.use((r: AxiosResponse) => {
    publish('request', {
        url: (r.config.baseURL ?? '') + '/' + r.config.url,
        method: r.config.method,
        request: {header: {Authorization: r.config.headers.Authorization}, data: r.config.data, ip: r.headers.ip},
        response: {status: r.request.status, data: r.request.response},
        duration: performance.getEntriesByName(r.request.responseURL).pop()?.duration ?? -1
    })
    return r.data
}, errHandler)

export default request
