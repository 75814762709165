import {req} from "@/lib/util";

export interface IActivityGift {
    gift_id: string
    tags: string[]
    name: string
    thumb_url: string
    image_url: string
    front_value: number
    cost_price_range: number[]
    taobao_keyword: string
    pdd_keyword: string
}

const _gifts: { [key: string]: IActivityGift[] } = {}

export const gifts = (tags: string[]): Promise<IActivityGift[]> => {
    const key = tags.sort().join('.')
    if (!_gifts[key] || _gifts[key].length == 0) {
        return new Promise((resolve, reject) => {
            req({
                url: 'editor/material/gifts', method: 'POST', data: {tags}, success: rs => {
                    _gifts[key] = rs;
                    resolve(rs);
                }, fail: reject
            })
        })
    }
    return Promise.resolve(_gifts[key])
}