import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import login, {account} from "@/app/login";
import templates from "@/app/templates";
import exception from "@/app/exception";
import {Modal} from "ant-design-vue";
import editor from "@/app/editor";
import merchant from "@/app/merchant";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [...templates, ...merchant, ...login, ...editor, ...exception,
        {path: '/:pathMatch(.*)', redirect: () => ({path: '/404'})}]
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
    // 只做登录守卫
    if (to.meta.requiresAuth) {
        if (!account.user?.uid && to.name !== 'login') next({name: 'login'})
        else if (account.user?.is_master && to.name == 'overview') {
            Modal.confirm({title: '提示', content: '超管不该入内。'})
            next({name: 'templates'})
        } else next()
    } else if (!!account.user?.uid && to.name === 'login') next({name: 'templates'})
    else next()
})

export {router}

export const draft = {tip: false}