import {Module} from "@/app/editor/module";
import {DefineComponent, reactive, UnwrapRef, watch} from "vue";
import {Activity} from "@/app/editor/activity";
import {account} from "@/app/login";
import {editor} from "@/app/editor/editor";
import {v1Share} from "@/app/editor/module/v1";
import ShopPreview from "@/app/editor/module/v1/shop/ShopPreview.vue";
import ShopSetup from "@/app/editor/module/v1/shop/ShopSetup.vue";
import {IModuleData} from "@/lib/activity";

export interface IActivityShop {
    phone_number: string
    sid: number
    icon: string
    shop_name: string
    tel: string
    area: string
    addr: string
    coordinate: number[]
}

export interface IAuthShop extends IActivityShop {
    expire_days: number
}


export interface IApplyAuthorization {
    apply_id: string
    apply_phone_number: string
    accept_at: string | null
    expire_days: number
    auth_count: number
}

export interface IShopConfigShop {
    phone_number: string
    sid: number
    icon: string
    shop_name: string
    tel: string
    area: string
    addr: string
    coordinate: number[]
}

export interface IShopConfig {
    shop: IShopConfigShop
}
export interface IShopAttach {
    shops: IActivityShop[]
    auth_shops: IAuthShop[]
    apply_authorizations: IApplyAuthorization[]
}

export class Shop extends Module {
    cfg: UnwrapRef<IShopConfig>;
    readonly attach: UnwrapRef<IShopAttach>

    constructor(a: Activity, m: UnwrapRef<IModuleData>) {
        super(a, m, '店家设置', 'men_dian', ShopPreview as DefineComponent, ShopSetup as DefineComponent);
        this.attach = reactive(m.attach as IShopAttach)
        this.cfg = m.config as IShopConfig
        const s = account.shop

        // 自动更正数据
        if (!this.cfg.shop) {
            this.cfg.shop = s ?? {
                phone_number: '',
                addr: "",
                area: "",
                icon: "",
                shop_name: "",
                sid: 0,
                tel: "",
                coordinate: [0, 0]
            }
        }
        v1Share.shop_name = this.cfg.shop.shop_name
        watch(() => this.cfg.shop, s => {
            if (editor.editor) {
                editor.editor.activity.data.sid = s.sid
                // editor.editor.activity.data.title = editor.editor.activity.toTitle(editor.editor.activity.data.titles.find(t => t.title_id == editor.activity?.status.title_id)?.title)
                v1Share.shop_name = s.shop_name
            }
        })
    }

    syncData() {
        if (editor.editor) {
            editor.editor.activity.data.sid = this.cfg.shop.sid
            this.cfg.shop = this.attach.shops.find(i => i.sid == this.cfg.shop.sid) ?? this.cfg.shop
            // editor.editor.activity.data.title = editor.activity.previewTitle(editor.activity.data.titles.find(t => t.title_id == editor.activity?.status.title_id)?.title)
        }
    }
    validate(): Error | null {
        if (this.cfg.shop.sid == 0) return new Error('至少要关联一家店')
        return null;
    }
}