import {RouteRecordRaw} from "vue-router";
import EditorSide from "@/app/editor/EditorSide.vue";
import EditorMenu from "@/app/editor/EditorMenu.vue";
import {editor} from "@/app/editor/editor";

export default <Array<RouteRecordRaw>>[
    {
        path: '/editor',
        name: 'editor',
        meta: {requiresAuth: true},
        components: {
            default: () => import(/* webpackChunkName: "editor.view" */ './EditorView.vue'),
            side: EditorSide,
            tabBar: EditorMenu,
        },
        beforeEnter: (): boolean => {
            // !!! ⚠️ 一定要提前清理，不然旧数据会污染新界面
            editor.editor = null
            return true
        }
    }
]
