import {version1} from "@/app/editor/module/v1";
import {version2} from "@/app/editor/module/v2";
import {DefineComponent} from "@vue/runtime-core";
import {Module} from "@/app/editor/module/index";
import {Activity} from "@/app/editor/activity";
import {IModuleData} from "@/lib/activity";

export class ModuleManager {
    private v = 0
    private m = ''
    private readonly modules: { [key: number]: { [key: string]: typeof Module } } = {}

    constructor() {
        version1(this.version(1))
        version2(this.version(2))
    }

    version(v: number): ModuleManager {
        this.v = v
        return this
    }

    module(m: string): ModuleManager {
        this.m = m
        return this
    }

    reg(m: typeof Module): ModuleManager {
        !this.modules[this.v] ? this.modules[this.v] = {} : null
        this.modules[this.v][this.m] = m
        return this
    }

    gen(a: Activity, m: IModuleData): Module {
        if (this.modules[m.version][m.module])
            return new this.modules[m.version][m.module](a, m, '', '', {} as DefineComponent, {} as DefineComponent)
        else throw new Error(`module "${m.module}" not found`)
    }

}

export const mm = new ModuleManager()