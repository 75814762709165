import {DefineComponent} from "@vue/runtime-core";
import {UnwrapRef} from "vue";
import {Activity} from "@/app/editor/activity";
import {IModuleData} from "@/lib/activity";

export interface IModule {
    readonly title: string
    readonly icon: string
    readonly activity: Activity;
    readonly data: UnwrapRef<IModuleData>;
    readonly set_up: DefineComponent
    readonly preview: DefineComponent


    validate(): Error | null
}

export class Module implements IModule {
    readonly title: string
    readonly icon: string
    readonly activity: Activity;
    readonly data: UnwrapRef<IModuleData>;
    public preview: DefineComponent;
    public set_up: DefineComponent;


    constructor(a: Activity, m: UnwrapRef<IModuleData>, title: string, icon: string, pc: DefineComponent, sc: DefineComponent) {
        this.activity = a
        this.data = m
        this.preview = pc
        this.set_up = sc
        this.title = title
        this.icon = icon
    }

    validate(): Error | null {
        return null;
    }
}

