import {Module} from "@/app/editor/module";
import {DefineComponent, UnwrapRef} from "vue";
import {Activity} from "@/app/editor/activity";
import {account} from "@/app/login";
import RecommendPreview from "@/app/editor/module/v1/recommend/RecommendPreview.vue";
import RecommendSetup from "@/app/editor/module/v1/recommend/RecommendSetup.vue";
import {IActivityGift} from "@/app/editor/material/gift";
import {IModuleData} from "@/lib/activity";

export interface IDistributionRuleProps {
    require: 'none' | 'pay'
    bind: 'view' | 'order' | 'pay'
    show_list: 'hide' | 'count' | 'sum'
}

export interface IDistributionRule {
    enable: boolean
    percents: number[]
    props: IDistributionRuleProps
}

export interface IGiftRules {
    freshman_count: number
    gifts: IActivityGift[]
}

export interface IRecommendConfig {
    distribution_rule: IDistributionRule
    gift_rules: IGiftRules[]
}
export class Recommend extends Module {
    cfg: UnwrapRef<IRecommendConfig>;

    constructor(a: Activity, m: UnwrapRef<IModuleData>) {
        super(a, m, '推荐设置', 'module_recommend', RecommendPreview as DefineComponent, RecommendSetup as DefineComponent);
        this.cfg = m.config as IRecommendConfig
    }

    max(): number {
        return Math.floor((account.shop?.wx_mch_profit_sharing_max_ratio ?? 3000) / 100) - 1
    }

    distributionAvailable(): boolean {
        return !!account.shop?.wx_mch_id && !!account.shop.wx_mch_profit_sharing_max_ratio
    }
}