import {connect, IClientOptions, MqttClient} from 'precompiled-mqtt'
import emit from "@/lib/emit";
import {random, req} from "@/lib/util";
import {account, IExpPackage} from "@/app/login";
import bowser from "bowser";
import {message} from "ant-design-vue";
import {InitOpenObserve} from "@/lib/openobserve";

export let conn: undefined | MqttClient
export const browserId: string = ((): string => {
    !localStorage.getItem('browserId') && localStorage.setItem('browserId', random(22))
    localStorage.browserId.length != 22 && localStorage.setItem('browserId', random(22))
    return localStorage.browserId
})()

interface IPkt {
    action: string
    res: unknown
}

export interface IAuth {
    user: string
    user_init_at: Date
    token: string
    expire: Date
    authorization: string
    experience: boolean
}

export let auth: IAuth | null = null

let pkts: IPkt[] = []

setInterval(() => {
    if (conn && conn.connected && pkts.length) {
        pkts.forEach(p => publish(p.action, p.res))
        pkts = []
    }
}, 5000)

export const reg = (fn: (token: IAuth) => void) => {
    if (auth !== null && auth.expire.valueOf() > new Date().valueOf()) fn(auth)
    else
        req({
            url: `open/session`,
            method: 'post',
            data: {
                code: browserId,
                agent: bowser.parse(navigator.userAgent),
                token: new URLSearchParams(location.search.slice(1)).get('token')
            },
            success: (res) => {
                account.shop = res.shop
                account.user = res.user

                if (process.env.NODE_ENV === 'production')
                    InitOpenObserve(account.user?.browser_id ?? '', account.user?.wx_union_id ?? '', (account.shop?.phone_number ?? 'guest') + '@djhdb.cn')
                account.exp_packages = res.exp_packages.map((c: IExpPackage) => {
                    c.expire_at = new Date(c.expire_at)
                    return c
                })
                res.token.expire = new Date(res.token.expire)
                res.token.user_init_at = new Date(res.token.user_init_at)
                create(res.token)
                fn(res.token) // fn 必须要在 create 之后 不然 create 方法可能会在fn调用之前再次调用reg
            },
            fail: () => {
                setTimeout(() => reg(fn), 3000)
            },
        })
}

export const create = (a: IAuth) => {
    auth = a
    auth.expire = new Date(a.expire)
    emit.emit('session.user', a.user)
    conn?.end()
    conn = connect('wss://broker.mywsy.cn/mqtt', {
        clientId: `activity/pc/${a.user}`,
        username: a.user,
        password: a.token,
        will: {
            topic: `h5/pc/activity/${browserId}/disconnect`,
            payload: new TextEncoder().encode(JSON.stringify({})),
            qos: 0,
            retain: false
        },
        protocolVersion: 5,
    } as IClientOptions)

    conn?.on('message', (topic, payload) => {
        // console.log('message:', topic, payload.toString())
        try {
            emit.emit(topic, JSON.parse(payload.toString()))
        } catch (e) {
            console.warn((e as Error).message)
        }
        if (topic == toTopic('exp_package')) {
            message.success('活动服务包购买成功')
            account.exp_packages.push(JSON.parse(payload.toString()))
        }
    })
    conn?.on('connect', () => {
        publish('connect', location.href)
        publish('browser', bowser.parse(navigator.userAgent))
        publish('performance', performance.getEntries())
        pkts.forEach(p => publish(p.action, p.res))
        pkts = []
        sub('exp_package')
    })
    conn?.on('error', e => {
        if (e.message.includes('Not authorized')) {
            reg(() => {
                //
            })
        }
    })
    conn?.on('disconnect', e => {
        console.log(e.reasonCode == 142)
    })
}

export const publish = (action: string, res: unknown) => {
    if (conn == undefined || !conn.connected)
        pkts.push({action, res})
    else {
        conn?.publish(`h5/pc/activity/${browserId}/${action}`, JSON.stringify(res), {
            qos: 1,
            retain: false
        }, (err) => {
            if (err) {
                console.error(err)
                pkts.push({action, res})
            }
        })
        // console.log(`h5/mp/${browserId}/${auth?.user}/${action}`, JSON.stringify(res))
    }
}

export const sub = (action: string) => {
    console.log('sub', toTopic(action))
    conn?.subscribe(toTopic(action), (err) => {
        if (err) console.error(err)
    })
}

export const unSub = (action: string) => {
    conn?.unsubscribe(toTopic(action), (err: unknown) => {
        if (err) console.error(err)
        else console.log('unSub', toTopic(action))
    })
}
export const toTopic = (action: string): string => `event/mp/activity/${browserId}/${action}`