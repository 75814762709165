import {Module} from "@/app/editor/module";
import {UnwrapRef} from "vue";
import {Activity} from "@/app/editor/activity";
import {DefineComponent} from "@vue/runtime-core";
import CustomerPreview from "@/app/editor/module/v1/customer/CustomerPreview.vue";
import CustomerSetup from "@/app/editor/module/v1/customer/CustomerSetup.vue";
import {IModuleData} from "@/lib/activity";


export class Customer extends Module {
    constructor(a: Activity, m: UnwrapRef<IModuleData>) {
        super(a, m, '顾客设置', 'gu_ke', CustomerPreview as DefineComponent, CustomerSetup as DefineComponent);
    }
}