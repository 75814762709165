import {reactive, UnwrapRef, watch} from "vue";
import {Module} from "@/app/editor/module";
import {mm} from "@/app/editor/module/module_manager";
import {message} from "ant-design-vue";
import {Json} from "@/lib/util";
import {variables} from "@/app/editor/material/variables";
import dayjs from "dayjs";
import emit from "@/lib/emit";
import {IActivityData} from "@/lib/activity";

export class Activity {
    readonly data: UnwrapRef<IActivityData>
    private currentCode: number

    constructor(data: IActivityData) {
        if (!data.activity_id) {
            // 创建
            data.start_at = new Date().toISOString()
            data.end_at = dayjs().add(7, 'day').toDate().toISOString()
        }
        this.data = reactive(data);
        this.currentCode = this.code()
        watch(this.data, () => {
            if (this.currentCode != this.code()) {
                this.currentCode = this.code()
                emit.emit('activity.code.change', this.currentCode)
            }
        })
    }

    toEditorModules(): Module[] {
        return this.data.modules.map(m => {
            try {
                return mm.gen(this, m)
            } catch (e) {
                console.error(e)
                throw e
            }
        })
    }

    variable(name: string, imageId?: string): string | number | null {
        let v: any = variables[name]
        let d: unknown = null
        try {
            if (imageId) {
                this.data.theme_config.theme_options[imageId].forEach(i => {
                    if (i.title == name) return i.text
                })
            }
            if (v === undefined) {
                return d as string | number | null
            }
            if (v['module']) {
                for (const m of this.data.modules) {
                    if (v['module'][m.module]) {
                        v = v['module'][m.module][m.version]
                        if (Array.isArray(v)) {
                            d = m.config
                            for (const k of v) {
                                d = (d as Json)[k]
                            }
                            /**
                             * 如果不在这里中断循环，会导致已经消费的 变量v 再次消费。当访问二级数组时，会导致崩溃！
                             * 且这里的崩溃会被层层拦截并导致外边的Promise永远无响应！
                             */
                            break
                        }
                    }
                }
            } else if (v['wx']) {
                if (v['wx'][0] == 'share' && v['wx'][1] == 'nick_name') {
                    this.data.modules.forEach(m => {
                        if (m.module == 'shop') d = ((m.config as Json)['shop'] as Json)['shop_name']
                    })
                } else if (v['wx'][0] == 'share' && v['wx'][1] == 'head_img_url') {
                    this.data.modules.forEach(m => {
                        if (m.module == 'shop') d = ((m.config as Json)['shop'] as Json)['icon']
                    })
                }
            }
        } catch (e) {
            console.info(e)
        }

        return d as string | number | null
    }


    validate(): boolean {
        if (!this.data.start_at) {
            message.error({content: '活动开始时间未设置'})
            return false
        }
        if (!this.data.end_at) {
            message.error({content: '活动结束时间未设置'})
            return false
        }
        if (this.data.end_at && this.data.start_at && new Date(this.data.end_at).valueOf() < new Date(this.data.start_at).valueOf()) {
            message.error({content: '活动结束时间不正确'})
            return false
        }
        if (!this.data.sid || this.data.sid <= 0) {
            message.error({content: '活动门店未设置'})
            return false
        }
        return true
    }


    code(): number {
        // console.log('code:', Object.keys(variables).map(k => this.variable(k)).join('') + JSON.stringify(this.data.theme_config) + this.data.title, this.hash(Object.keys(variables).map(k => this.variable(k)).join('') + JSON.stringify(this.data.theme_config) + this.data.title))
        return this.hash(Object.keys(variables).map(k => this.variable(k)).join('') + JSON.stringify(this.data.theme_config.theme_options))
    }

    hash(s: string): number {
        return s.split('').reduce((a, b) => {
            a = ((a << 5) - a) + b.charCodeAt(0);
            return a & a
        }, 0)
    }

    genImageTemplateData(): Json {
        const o = {
            modules: this.data.modules,
            wx: {me: {head_img_url: '', nick_name: ''}}
        }
        this.data.modules.forEach(m => {
            if (m.module == 'shop') {
                o.wx.me.head_img_url = 'https://b.s.mywsy.cn/media/image/default_header.jpg'
                o.wx.me.nick_name = '微信昵称'
            }
        })
        return o
    }
}