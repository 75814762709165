import {RouteRecordRaw, RouterView} from "vue-router";
import TabBarView from "@/components/TabBarView.vue";
import SharePage from "@/app/merchant/share/SharePage.vue";
import ActivitiesSide from "@/app/merchant/activity/ActivitiesSide.vue";

export default <Array<RouteRecordRaw>>[
    {
        path: '/merchant',
        name: 'merchant',
        meta: {requiresAuth: true},
        components: {
            default: RouterView,
            side: ActivitiesSide,
            tabBar: TabBarView,
        },
        children: [
            {
                path: '',
                name: 'overview',
                meta: {requiresAuth: true},
                component: () => import(/* webpackChunkName: "overview.view" */ './overview/OverView.vue')
            },
            {
                path: 'activity',
                name: 'activity',
                meta: {requiresAuth: true},
                component: () => import(/* webpackChunkName: "activity.view" */ './activity/ActivityView.vue')
            }
        ]
    },
    {
        path: '/activity/share',
        name: 'activityShare',
        meta: {requiresAuth: true},
        components:{
            default: SharePage,
            tabBar: TabBarView,
        }
    }
]


export const MoneyTypes = ['活动结算', '邀请返佣', '提现', '充值会员', '手续费', '手续费返还', '收入-全额', '红包',
    '扣除异常收入', '退回异常支出', '分销佣金', '收入-余款', '收入-定金', '退款', '退款失败', '总部分成', '门店分成', '分销返利',
    '任务奖励', '众筹礼金', '众筹礼金手续费', '系统扣除', '分账-代运营收入', '分账-商家支出', '团长免单-商家支出', '系统增加',
    '活动策划扣除', '三方活动创建充值', '三方活动创建扣款', '活动分账-扣除', '活动分账', '员工奖励', '退款-分佣返利扣除',
    '收入-二维码收款', '退款-员工奖励扣除', '特殊结算冻结', '特殊结算解冻']

export const OrderStatus = ['未付款', '待成团', '待付余款', '待核销', '退款审核', '退款中', '订单关闭', '订单完成']