import {Basic} from "@/app/editor/module/v1/basic";
import {ModuleManager} from "@/app/editor/module/module_manager";
import {reactive} from "vue";
import dayjs, {Dayjs} from "dayjs";
import {Shop} from "@/app/editor/module/v1/shop/shop";
import {IThemeConfig} from "@/app/editor/material/theme";
import {Group} from "@/app/editor/module/v1/group/group";
import {Forward} from "@/app/editor/module/v1/forward/forward";
import {Recommend} from "@/app/editor/module/v1/recommend/recommend";
import {Introduction} from "@/app/editor/module/v1/introduction/introduction";
import {Customer} from "@/app/editor/module/v1/customer/customer";


export const version1 = (m: ModuleManager) => {
    m.module('basic').reg(Basic)
    m.module('shop').reg(Shop)
    m.module('group').reg(Group)
    m.module('forward').reg(Forward)
    m.module('recommend').reg(Recommend)
    m.module('introduction').reg(Introduction)
    m.module('customer').reg(Customer)
}

interface IActivityStatus {
    is_distribution: boolean
    distribution_list: 'hide' | 'count' | 'sum'
    buy_button_text: string
    date_range: Dayjs[]
    theme_props: IThemeConfig
    theme_ids: string[]
    title_id: string
    sell_price: number
    rules: { [type: number]: string[] }
    shop_name: string
}

export const v1Share = reactive({
    date_range: [dayjs(), dayjs().add(7, 'days')],
    is_distribution: false,
    distribution_list: 'hide',
    buy_button_text: '立即抢购',
    theme_props: {
        theme_id: '',
        theme_options: {},
        applet_thumb_version: 0,
        applet_poster_id: ''
    },
    theme_ids: [],
    title_id: '',
    sell_price: 0,
    rules: [],
    shop_name: ''
} as IActivityStatus)